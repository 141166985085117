@import 'assets/styles/_theme';
@import 'assets/styles/_colors';
@import 'assets/styles/_fonts';
@import 'assets/styles/input';
@import 'assets/fonts/open-sans.css';
@import 'constants';

*:not(mat-icon) {
  font-family:
    Open Sans,
    sans-serif !important;
}

:root {
  --colorPrimaryRgb: 1, 169, 202;
  --colorPrimary: rgb(var(--colorPrimaryRgb));
}

// Removes highlight from the selected tenant in the systembar
.service-tab-item_menu {
  [data-component-id='active-tenant'] .mat-mdc-menu-item-text {
    font-weight: normal !important;
  }
}

.sacc-snackbar-list {
  position: fixed;
  z-index: 10000000;
  bottom: 2rem;
  left: 3rem;
  filter: none !important;
}

body {
  margin: 0;
  height: 100vh;
  font-size: 1.3rem;
  overflow-x: hidden;
}

.mdc-switch__icons {
  display: none;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a:link {
  text-decoration: none;
  color: $color-blue-gray-900;
}

a:hover {
  color: $color-primary;
}

.logo {
  height: 4.8rem;
}

.account-logo {
  height: 3rem;
  min-width: 3rem;
  margin-right: 1.2rem;
}

.hidden {
  display: none !important;
}

/* MATERIAL TABLE RESTYLE */

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 0.3rem;
}

.mat-mdc-tab {
  flex-grow: 0 !important;
  padding: 0.8rem 0 0.7rem !important;
  height: unset !important;

  &-header {
    margin-bottom: 4.8rem;
    border-bottom: unset;
  }

  &-labels {
    gap: 1.3rem;
  }

  &-body-content {
    overflow: hidden !important;
  }

  &:not(.mat-mdc-tab-disabled) {
    .mdc-tab__text-label {
      color: $color-blue-gray-900 !important;
    }

    &.mdc-tab--active .mdc-tab__text-label {
      color: $color-blue-gray-900 !important;
      font-weight: 600 !important;
    }

    .mdc-tab-indicator__content--underline {
      border-width: 0.3rem;
    }
  }

  .mdc-tab {
    &__ripple::before {
      background-color: unset !important;
    }

    &__text-label {
      font: $font-body;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.chip-list-field {
  min-width: 24rem;
}

.menu-button {
  opacity: 0.7;
}

.ellipsis-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Make sure to not influence the style of mat-calendars in dialogs
table:not(.mat-calendar-table) {
  width: 100%;
  min-width: 86rem;
  border-top: rgba(0, 0, 0, 0.12) 0.1rem solid !important;
}

tr.mat-header-row,
tr.mat-mdc-header-row {
  height: 2.3rem !important;
}

.mat-mdc-table .mdc-data-table__row {
  height: 4.8rem !important;
}

.mat-mdc-table .mat-sort-header-sorted .mat-sort-header-content {
  color: $color-blue-gray-900;
}

.mat-mdc-table .mat-sort-header:hover {
  background-color: rgba($color-blue-gray-800, 0.08);
  transition: background-color 0.1s ease-in;
}

// Style the sorting icons of the table
.mat-mdc-table .mat-sort-header-arrow {
  color: $color-blue-gray-900;

  svg {
    height: 1.8rem;
    width: 1.8rem;
    margin: -0.9rem 0 0 -0.9rem;
  }
}

.mat-header-cell,
.mat-mdc-header-cell {
  color: #0000008a;
  padding-right: 1rem !important;
  font-weight: 500;
  font-size: 1.2rem;
  border-bottom-color: #0000001f;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 0 0 1.6rem !important;
}

.mat-mdc-drawer-container {
  background-color: white;
}

.mat-mdc-drawer-content {
  z-index: unset !important;

  &.inner-drawer-content {
    overflow-y: scroll;
  }
}

.mat-mdc-progress-spinner circle,
.mat-mdc-spinner circle {
  stroke: $color-blue-gray-300;
}

.mat-mdc-option:hover:not(.mat-mdc-option-disabled),
.mat-mdc-option:focus:not(.mat-mdc-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-cell {
  font-size: 1.3rem;
  line-height: 1.8rem;
  padding-right: 1.6rem !important;
  border-bottom-color: #0000001f;
  // Fixes a bug with the hover-background color on macos
  background: unset !important;
}

.mat-mdc-column-actions {
  width: 4rem;

  .material-icons {
    font-size: 1.6rem;
  }
}

.mat-mdc-menu-item .mat-icon {
  margin-right: 0.8rem !important;
  height: 1.8rem !important;
  width: 1.8rem !important;
}

button.mat-mdc-menu-item .mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
  height: 3.2rem !important;
}

button.mat-mdc-menu-item .mdc-list-item__primary-text {
  display: flex;
  align-items: center;
  height: 3.2rem !important;
  font-size: 1.3rem !important;
  line-height: 1.8rem !important;
}

.mat-mdc-menu-panel {
  background-color: white;
  min-height: unset !important;
  overflow: hidden !important;
}

.mat-mdc-standard-chip {
  border-radius: 0.4rem !important;
  min-height: 2.6rem !important;
  background-color: $color-blue-gray-050 !important;
  margin: 0 0 1rem 1rem !important;

  & .mat-mdc-chip-remove.mat-mdc-icon {
    margin: 0;
    font-size: 1.3rem !important;
    display: flex;
  }
}

.mat-mdc-basic-chip {
  background-color: $color-blue-gray-050 !important;
}

.mat-column-name,
.mat-mdc-column-name {
  min-width: 15rem;
}

.mat-column-status-date,
.mat-mdc-column-status-date {
  min-width: 17rem;
}

.mat-column-name,
.mat-mdc-column-name,
.mat-column-email,
.mat-mdc-column-email {
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-column-actions {
  width: 4rem;
}

@media only screen and (max-width: 144rem) {
  .mat-column-name,
  .mat-mdc-column-name,
  .mat-column-email,
  .mat-mdc-column-email {
    max-width: 22.5rem;
    padding-left: 0.7rem !important;
  }
}

.mat-mdc-option-pseudo-checkbox {
  display: none !important;
}

.mat-mdc-option-text {
  font-size: 1.3rem;
  line-height: 1.8rem;
  color: rgba($color-blue-gray-900, 0.94);
}

.mat-mdc-option {
  height: 3.2rem !important;
  line-height: 3rem !important;
}

.cdk-global-overlay-wrapper {
  overflow: auto;
}

.celum-heartbeat {
  height: 60%;
  width: 100%;
  opacity: 0;
  animation: wiggle 1.1s infinite;
  will-change: transform, opacity;
  margin-top: 10%;
}

.celum-chip_text {
  margin-bottom: 0.1rem;
}

@keyframes wiggle {
  0% {
    transform: scale(1.1);
    opacity: 1;
  }
  50% {
    transform: scale(1.07);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.1);
    opacity: 1;
  }
}

.info-note {
  height: 1.8rem;
  width: 1.8rem;
}

.mdc-checkbox__checkmark-path,
.mdc-mdc-checkbox__checkmark-path {
  color: white;
}

.mat-mdc-checkbox-inner-container {
  width: 1.6rem !important;
  height: 1.6rem !important;
}

.mat-mdc-tooltip {
  padding: 0.1rem 0;

  .mdc-tooltip__surface {
    white-space: pre-line;
    max-width: 36rem;
    line-height: 1.27 !important;
  }
}

.mat-mdc-slide-toggle-bar {
  width: 2.6rem !important;
}

.mat-mdc-slide-toggle-thumb-container {
  top: -0.2rem !important;
}

.mat-mdc-slide-toggle-thumb-container,
.mat-mdc-slide-toggle-thumb {
  height: 1.8rem !important;
  width: 1.8rem !important;
}

.mat-mdc-slide-toggle-content {
  margin-left: 0.8rem;
}

.cdk-overlay-pane {
  .celum-avatar-list-menu {
    // Width of the avatar list menu - takes into consideration that the scrollbar is always visible
    min-width: 16.5rem;
    max-width: 16.5rem;

    width: 100%;
    // Fixes horizontal scrollbar in Chrome
    overflow-x: hidden;
    // Fixes smaller sizing issue in FF/Safari
    flex-shrink: 0;
    max-height: 18.3rem !important;
  }
}

.mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-thumb {
  background-color: var(--colorPrimary);
}

// do not show scrollbar when opening dialogs
html.scroll-disabled,
html.scroll-disabled body {
  overflow-y: hidden;
  height: 100%;
}

.celum-input-form-field {
  .mat-mdc-form-field-infix {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .mat-mdc-form-field-icon-suffix {
    top: 0 !important;
  }
}

mat-radio-button {
  .mdc-label {
    color: $color-blue-gray-900;
  }

  .mat-mdc-radio-touch-target {
    width: 1.8rem;
    height: 1.8rem;
  }

  // Ripple that is shown on hover, focus, etc.
  .mdc-radio .mdc-radio__background::before {
    transform: scale(0.8) !important;
    top: -1.3rem !important;
    left: -1.3rem !important;
  }

  // Selected state
  &.mat-mdc-radio-checked {
    .mdc-radio__outer-circle {
      border-color: $color-primary !important;
    }

    .mdc-radio__inner-circle {
      transform: scale(0.4) !important;
      border-color: $color-primary !important;
    }

    // Focus ripple
    &.cdk-focused,
    &.cdk-mouse-focused {
      .mdc-radio {
        .mdc-radio__background::before {
          opacity: 0.12 !important;
          background-color: $color-primary !important;
        }
      }
    }

    // Hover ripple
    &:hover {
      .mdc-radio .mdc-radio__background::before {
        opacity: 0.08 !important;
        background-color: $color-primary !important;
      }
    }
  }

  // Unselected state
  &:not(.mat-mdc-radio-checked) {
    .mdc-radio__outer-circle {
      border-color: $color-blue-gray-300 !important;
    }

    // Focus ripple
    &.cdk-focused,
    &.cdk-mouse-focused {
      .mdc-radio {
        .mdc-radio__background::before {
          opacity: 0.12 !important;
          background-color: $color-blue-gray-300 !important;
        }
      }
    }

    // Hover ripple
    &:hover {
      .mdc-radio .mdc-radio__background::before {
        opacity: 0.08 !important;
        background-color: $color-blue-gray-300 !important;
      }
    }
  }
}

.mat-mdc-dialog-container {
  // Fixes dialog sizing issues
  max-width: inherit !important;
}

.dialog-confirm-menu {
  width: 42.4rem;
  max-width: 42.4rem !important;
}

.select-panel {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.sacc-popup {
  &_panel {
    .mat-mdc-dialog-surface {
      box-shadow:
        0 0.1rem 0.8rem 0 rgba($color-blue-gray-900, 0.2),
        0 0.3rem 0.3rem -0.2rem rgba($color-blue-gray-900, 0.12),
        0 0.3rem 0.4rem 0 rgba($color-blue-gray-900, 0.14) !important;
    }
  }

  &_invisible-backdrop {
    background-color: transparent;
  }
}

.sacc-federation-dialog-panel mat-dialog-container {
  max-height: 100% !important;
}

// fix for doubled tooltips in safari
[spaceAwareTooltip]::after,
[spaceAwareTooltip] > *::after {
  content: '';
  display: block;
}

lookup-area mat-form-field {
  // on some small screens and when zooming in, the bottom border "gets lost", make sure to show it always
  overflow: visible !important;
}
